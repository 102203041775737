
.experience-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  padding: 2rem;
  justify-items: center;
  align-items: stretch;
}

.project-card {
  width: 100%;
  max-width: 250px;
  height: 350px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  overflow: hidden;
  position: relative;
}

.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
}

.card-content {
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.card-content h3 {
  font-size: 1.5rem;
  color: var(--orange);
  margin-bottom: 10px;
}

.card-content p {
  font-size: 1rem;
  color: var(--white);
  margin-bottom: 20px;
}

.details-button {
  padding: 10px 20px;
  background-color: var(--orange);
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin-bottom: 30px;
}

.details-button:hover {
  background-color: #e07b20;
}

/* Extra feature: Floating project card background */
.project-card::before {
  content: '';
  position: absolute;
  top: -50px;
  left: -50px;
  width: 150%;
  height: 150%;
  background-image: linear-gradient(45deg, #f9a826, #fd523f);
  z-index: -1;
  transition: all 0.5s ease;
  transform: rotate(-15deg);
  opacity: 0;
}

.project-card:hover::before {
  opacity: 0.8;
  transform: rotate(0deg);
}

/* Dark mode styles */
.project-card.dark {
  background-color: #333;
  color: white;
}

@media screen and (max-width: 768px) {
  .experience-grid {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
}

@media screen and (max-width: 480px) {
  .project-card {
    max-width: 200px;
    height: 300px;
  }

  .card-content h3 {
    font-size: 1.2rem;
  }

  .card-content p {
    font-size: 0.9rem;
  }

  .details-button {
    font-size: 0.9rem;
    
  }
}

.portfolio1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top:150px;

}
/* heading */
.portfolio1 span:nth-of-type(1) {
  color: var(--black);
  font-size: 2rem;
  font-weight: bold;
}

.portfolio1 span:nth-of-type(2) {
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: bold;
}