.tech-section {
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem; /* Space between the cards */
    justify-content: center; /* Center the cards */
  }
  
  .tech-card {
    background-color: #1e1e1e;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: calc(33.33% - 1rem); /* Three cards in a row with gap */
    text-align: center;
    box-sizing: border-box;
    transition: box-shadow 0.3s, background-color 0.3s;
    min-height: 250px; /* Ensure cards have enough height */
  }
  
  .tech-card:hover {
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.8);
  }
  
  .card-title {
    color: var(--orange);
    font-family: "Gumela";
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .tech-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    gap: 1rem; /* Space between buttons */
    justify-content: center; /* Center buttons */
  }
  
  .tech-item {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #282828;
    padding: 0.5rem 0.75rem; /* Adjust padding for smaller buttons */
    border-radius: 8px;
    cursor: pointer;
    transition: box-shadow 0.3s, background-color 0.3s, border 0.3s;
    box-shadow: none;
    border: 2px solid rgba(255, 255, 255, 0.2);
  }
  
  .tech-item:hover {
    background-color: #1e1e1e;
    box-shadow: 0 0 15px 5px rgba(255, 255, 255, 0.8);
    border: 2px solid rgba(255, 255, 255, 0.8);
  }
  
  .tech-icon {
    width: 20px; /* Reduced icon size */
    height: 20px; /* Reduced icon size */
    margin-right: 8px; /* Reduced margin */
  }
  
  .tech-item-text {
    color: white;
    font-size: 0.9rem; /* Reduced font size */
  }
  
  /* Responsive styles */
  @media (max-width: 1200px) {
    .tech-card {
      width: calc(50% - 1rem); /* Two cards in a row */
    }
  }
  
  @media (max-width: 900px) {
    .tech-card {
      width: calc(100% - 1rem); /* One card in a row */
    }
  }
  